//only for development
//TO DO: fix fix env dev storyblok token
require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`
});

module.exports = {
  siteMetadata: {
    title: "Asia Portfolio",
    description: `Portfolio 2020`,
    author: `Asia Jackowska`,
    siteUrl: `https://www.ajackowska.co`
  },
  plugins: [
    `gatsby-plugin-styled-components`,
    "gatsby-plugin-react-helmet",
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "kM2An21Ebd7hM8TxXj3Jewtt",
        homeSlug: "home",
        version: process.env.NODE_ENV === "production" ? "published" : "draft"
      }
    },

    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`
      }
    },
    {
      resolve: `gatsby-transformer-sharp`
    },
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: [`Lato\:400,700`]
      }
    }
  ]
};
