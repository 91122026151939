import React from "react";
import config from "../../gatsby-config";

import Layout from "./../components/layout";

//styled components
const sbConfigs = config.plugins.filter(item => {
  return item.resolve === "gatsby-source-storyblok";
});
const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {};

const loadStoryblokBridge = function(cb) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`;
  script.onload = cb;
  document.getElementsByTagName("head")[0].appendChild(script);
};

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      story: null,
      globalNavi: { content: {} },
      test: null
    };
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
    });
  }

  loadStory() {
    window.storyblok.get(
      {
        slug: window.storyblok.getParam("path"),
        version: "draft",
        resolve_relations: sbConfig.options.resolveRelations || []
      },
      data => {
        this.setState({ story: data.story });
        this.loadGlovalNavi(data.story.lang);
      }
    );
  }

  loadTest() {
    console.log("window.storyblok", window.storyblok);
    window.storyblok.get(
      {
        version: "draft",
        resolve_relations: sbConfig.options.resolveRelations || []
      },
      data => {
        // console.log("data", data);
        this.setState({ test: data });
      }
    );
  }

  loadGlovalNavi(lang) {
    const language = lang === "default" ? "" : lang + "/";
    window.storyblok.get(
      {
        slug: `${language}global-navi`,
        version: "draft"
      },
      data => {
        this.setState({ globalNavi: data.story });
      }
    );
  }

  initStoryblokEvents() {
    this.loadStory();
    let sb = window.storyblok;

    sb.on(["change", "published"], payload => {
      this.loadStory();
    });

    sb.on("input", payload => {
      // console.log("payload", payload);
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        );
        this.setState({ story: payload.story });
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    if (this.state.story == null) {
      return <div></div>;
    }

    let content = this.state.story.content;
    let globalNavi = this.state.globalNavi.content;
    let dateCreated = this.state.story.created_at;

    return (
      <div>
        <Layout
          globalNavi={globalNavi}
          content={content}
          dateCreated={dateCreated}
        />
      </div>
    );
  }
}

export default StoryblokEntry;
